<template>
<div id="page-articulos-equivalencias" class="non-printable">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height class="non-printable">
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Artículos Equivalencias" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="success" @click.native="modalImportar=true" title="Importar Equivalencias" v-tippy>
              <v-icon dark>mdi-file-import</v-icon>
            </v-btn>
            &nbsp;
            <exportar-excel :data="registros.items" :exportFields="encabezadoLayoutExportar" name="EquivalenciasArticulos.xlsx">
                <v-btn id="botonExportar" fab dark x-small color="green darken-2" title="Exportar a Excel" v-tippy>
                  <v-icon dark>mdi-table</v-icon>
                </v-btn>
              </exportar-excel>&nbsp;
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="openModalAjuste(1)" title="Agregar Equivalencias" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="codigo_articulo" slot-scope="props">
                  {{props.row.codigo_articulo.join(", ")}}
                </template>
                <template slot="codigo_articulo_equivalente" slot-scope="props">
                  {{props.row.codigo_articulo_equivalente.join(", ")}}
                </template>
                <template slot="cantidad" slot-scope="props">
                  <div style="text-align:center;">{{props.row.cantidad}}</div>
                </template>
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>


                <template slot="actions" slot-scope="props">
                  <v-speed-dial direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue lighten-3" @click.native="editarRegistro(props.row)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row)" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>
                </template>

              </v-client-table>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL AJUSTE -->
  <v-dialog v-model="modalAjuste" max-width="60%" persistent>
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Artículo Equivalencia</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalAjuste = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formajuste" lazy-validation>
          <v-container grid-list-md>
            <v-row justify="center">

              <v-autocomplete v-model="model.id_articulo" :items="articulos" :hide-no-data="true" :loading="isLoading" ref="selectarticulo" :search-input.sync="search" placeholder="Ingrese nombre de artículo para buscar" prepend-icon="search"
                :hide-selected="true" item-text="nombre" item-value="_id" @change="setNombreArticulo()" label="Artículo" :rules="[rules.required]" v-if="update==false">
              </v-autocomplete>
              <v-text-field label="Artículo" v-model="model.nombre_articulo" v-if="update==true" readonly disabled :rules="[rules.required]"></v-text-field>
            </v-row>

            <v-row justify="center">
              <v-col sm="2" md="2" lg="2" align-self="center" justify="center">
                Equivale a:<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<v-icon>mdi-arrow-down-bold</v-icon>
              </v-col>
            </v-row>

            <v-row justify="center">

              <v-col sm="3" md="3" lg="3">
                <v-text-field label="Cantidad Desgranar" v-model="model.cantidad" :rules="[rules.required, validaCantidadFloat]"></v-text-field>
              </v-col>

              <v-autocomplete v-model="model.id_articulo_equivalente" :items="articulos_equivalentes" :hide-no-data="true" :loading="isLoadingEquivalente" :search-input.sync="searchEquivalente" placeholder="Ingrese nombre de artículo para buscar"
                prepend-icon="search" :hide-selected="true" item-text="nombre" item-value="_id" @change="setNombreEquivalente()" label="Artículo" :rules="[rules.required, validaEquivalente]" v-if="update==false">
              </v-autocomplete>
              <v-text-field label="Artículo" v-model="model.nombre_articulo_equivalente" v-if="update==true" readonly disabled :rules="[rules.required]"></v-text-field>
              <v-col sm="3" md="3" lg="3" align-self="center" justify="center">
                <v-checkbox v-model="model.compra_a_proveedor" label="Así se compra a proveedor" :true-value="1" :false-value="0"></v-checkbox>
              </v-col>

            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalAjuste=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalImportar" max-width="39%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">mdi-file-import</v-icon>&nbsp;
          <span class="subheading">
            <strong>Importar Equivalencias</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalImportar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>

        <v-container grid-list-md>
          <v-row justify="center">
            <v-col sm="6" md="6" lg="6">
              Importar Equivalencias
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <exportar-excel :data="camposLayout" :exportFields="encabezadoLayout" name="Plantilla_Equivalencias.xlsx">
                <v-btn fab dark x-small color="indigo darken-2" title="Plantilla Equivalencias" v-tippy>
                  <v-icon dark>receipt</v-icon>
                </v-btn>
              </exportar-excel>
            </v-col>
            <v-col sm="1" md="1" lg="1">
              <upload-excel-component title="Importar Equivalencias" idInput="EquivalenciasKey" :on-success="handleSuccess" :before-upload="beforeUpload" />
            </v-col>
          </v-row>

        </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalImportar = false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
export default {
  watch: {
    search(val) {
      //Si no ha escrito 4 letras o mas
      if (val == null || val.length < 4) return
      // Si todavía esta buscando
      if (this.isLoading) return
      this.isLoading = true
      let data = {
        "selector": {          
          "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
          "type": "articulos"
        }        
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            response.data.docs.sort(this.ordenar_nombre);
            this.articulos = response.data.docs;
          } else
            this.articulos = [];

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los articulos.",
            footer: ""
          });
        }).then(() => {
          this.isLoading = false;
        });
    },
    searchEquivalente(val) {
      //Si no ha escrito 4 letras o mas
      if (val == null || val.length < 4) return
      // Si todavía esta buscando
      if (this.isLoadingEquivalente) return
      this.isLoadingEquivalente = true
      let data = {
        "selector": {          
          "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
          "type": "articulos"
        }        
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            response.data.docs.sort(this.ordenar_nombre);
            this.articulos_equivalentes = response.data.docs;
          } else
            this.articulos_equivalentes = [];

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los articulos.",
            footer: ""
          });
        }).then(() => {
          this.isLoadingEquivalente = false;
        });
    },
  },
  mounted: function() {
    if (!this.verificaPermiso('t.inventarios.equivalencias')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.registros.items = [];
    this.get_datos();
    this.sb_sucursal = this.$local_storage.get("sb_sucursal", "");
    this.model.usuario = this.$local_storage.get("sb_usuario");
  },
  data() {
    return {
      modalImportar: false,
      camposObligatorios: [
        "codigo_barras_articulo_origen",
        "codigo_barras_articulo_equivalente",
        "cantidad_equivalente",
      ],
      encabezadoLayoutExportar: {
        "Código":"codigo_articulo.0",
        "Artículo":"nombre_articulo",
        "Cantidad a Desgranar":"cantidad",
        "Código Equivalente":"codigo_articulo_equivalente.0",
        "Artículo Equivalente":"nombre_articulo_equivalente",
      },
      encabezadoLayout: {
        codigo_barras_articulo_origen: "codigo_barras_articulo_origen",
        codigo_barras_articulo_equivalente: "codigo_barras_articulo_equivalente",
        cantidad_equivalente: "cantidad_equivalente",
        compra_a_proveedor: "compra_a_proveedor"
      },
      //van vacíos para solo descargar layout con encabezados
      camposLayout: [{
        codigo_barras_articulo_origen: "[Código de Barras del artículo a desgranar/original]",
        codigo_barras_articulo_equivalente: "[Código de Barras del artículo equivalente en el cual se desgranará el original]",
        cantidad_equivalente: "[Cantidad equivalente; es decir, 1 unidad del original equivale a cuántas unidades del articulo equivalente]",
        compra_a_proveedor: "[ 0 - Indica que al proveedor se le compra el articulo origen, 1 - Indica que al proveedor se le compra el articulo equivalente. Default es 0]"
      }],
      articulos_all: [],
      isLoading: false,
      isLoadingEquivalente: false,
      sb_sucursal: "",

      model_filters: "",
      fab: [],

      modalAjuste: false,
      name: "datagrid",
      columns: [
        "codigo_articulo", "nombre_articulo",
        "cantidad",
        "codigo_articulo_equivalente", "nombre_articulo_equivalente",
        "actions"
      ],
      options: {
        perPage: 10,
        pagination: {
          show: true
        },
        filterable: ["codigo_articulo", "nombre_articulo",
          "cantidad",
          "codigo_articulo_equivalente", "nombre_articulo_equivalente",
        ],
        headings: {
          "codigo_articulo": "Código",
          "nombre_articulo": "Artículo",
          "cantidad": "Cantidad a Desgranar",
          "codigo_articulo_equivalente": "Código Equivalente",
          "nombre_articulo_equivalente": "Artículo Equivalente",
          "actions": "Operaciones"
        },
      },


      update: false,

      model: {
        _id: "",
        _rev: "",
        id_articulo: "",
        nombre_articulo: "",
        codigo_articulo: "",
        compra_a_proveedor: 0, //Indica si asi es como se compra a proveedor 1 o 0
        id_articulo_equivalente: "",
        nombre_articulo_equivalente: "",
        codigo_articulo_equivalente: "",
        cantidad: "",
        usuario: "",
      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
          href: "",
          disabled: true,
        },
        {
          text: "Artículos Equivalencias",
          href: ""
        }
      ],

      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      searchEquivalente: "",
      registros: {
        selected: [],
        items: []
      },
      sucursales: [],
      articulos: [],
      articulos_equivalentes: [],

    };
  },
  methods: {
    ordenar_nombre: function(a, b) {
      if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
        return -1;
      }
      if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    get_datos: function() {
      var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias';
      axios.get(url)
        .then(response => {
          if (response.data.rows.length > 0) {
            response.data.rows.forEach((item) => {
              this.registros.items.push(item.value);
            });
          }
        })
        .catch(error => {
          console.log(error)
          window.dialogLoader.hide();
        })
    },
    setNombreArticulo: function() {
      this.model.nombre_articulo = this.getNombreArticulo(this.model.id_articulo);
      this.model.codigo_articulo = this.getCodigoArticulo(this.model.id_articulo);
    },
    setNombreEquivalente: function() {
      this.model.nombre_articulo_equivalente = this.getNombreArticulo(this.model.id_articulo_equivalente, "equivalente");
      this.model.codigo_articulo_equivalente = this.getCodigoArticulo(this.model.id_articulo_equivalente, "equivalente");
    },

    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },

    getNombreArticulo: function(id_articulo, opcion = "normal") {
      if (opcion == "normal")
        var filter = this.articulos.find(e => e._id == id_articulo);
      else
        var filter = this.articulos_equivalentes.find(e => e._id == id_articulo);

      if (filter)
        return filter.nombre;
      else
        return ""
    },
    getCodigoArticulo: function(id_articulo, opcion = "normal") {
      if (opcion == "normal")
        var filter = this.articulos.find(e => e._id == id_articulo);
      else
        var filter = this.articulos_equivalentes.find(e => e._id == id_articulo);

      if (filter)
        return filter.codigo_barras;
      else
        return ""
    },
    getNombreSucursal: function(id_sucursal) {
      var filter = this.all_sucursales.find(e => e._id == id_sucursal);
      if (filter)
        return filter.nombre;
      else
        return ""
    },
    getLetraSucursal: function(id_sucursal) {
      var filter = this.all_sucursales.find(e => e._id == id_sucursal);

      if (filter)
        return filter.clave;
      else
        return "X";
    },

    validaEquivalente: function(val) {
      if (this.model.id_articulo != null && this.model.id_articulo != "") {
        if (this.model.id_articulo == val)
          return "El artículo no puede ser el mismo.";
      }
      return true;
    },


    verArticulos: function(row) {
      this.articulos_vista = row.articulos;
      this.model_vista = row;
      this.modalArticulos = true;
    },

    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else
        return true;

    },

    getSucursales: function() {
      let data = {
        "selector": {
          "estatus": {
            "$eq": "Activo"
          }
        },
        "sort": [
          "nombre"
        ],
        /*"fields": [
            "nombre", "estatus"
        ]*/
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/sucursales/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {

            this.all_sucursales = response.data.docs;
            var filter = response.data.docs.filter(e => {
              return e._id != this.model.id_sucursal_origen;
            });
            this.sucursales = filter;
          } else
            this.sucursales = [];

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las sucursales.",
            footer: ""
          });

        });
    },
    openModalAjuste: function(type) {
      if (type === 1) {
        this.update = false;

        this.model._id = "";
        this.model._rev = "";
        this.model.id_articulo = "";
        this.model.nombre_articulo = "";
        this.model.codigo_articulo = "";
        this.model.compra_a_proveedor = 0;
        this.model.id_articulo_equivalente = "";
        this.model.nombre_articulo_equivalente = "";
        this.model.codigo_articulo_equivalente = "";
        this.model.cantidad = "";
      } else {
        this.update = true;
      }
      this.modalAjuste = true;
    },

    validarelacion: function() {
      var self = this;
      return new Promise(function(resolve, reject) {

        var where = {
          "id_articulo": self.model.id_articulo,
          "id_articulo_equivalente": self.model.id_articulo_equivalente,
        };

        axios({
            method: 'POST',
            url: process.env.VUE_APP_COUCHDB_URL + '/articulos_equivalencias/_find/',
            withCredentials: true,
            data: {
              selector: where,
              sort: ["id_articulo"],
            },
          }).then(response => {
            if (response.data.docs != null && response.data.docs.length > 0) {
              return reject(false);
            } else {
              return resolve(true);
            }
          })
          .catch(error => {
            console.log(error)
            return reject(false);
          })

      });
    },
    setProveedorArticulo: function(id_articulo, id_articulo_equivalente) {

      let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/set_compra_proveedor/" + id_articulo;
      let data = {
        "id_articulo_equivalente": id_articulo_equivalente,
      }

      window.axios
        .post(url, data)
        .then(response => {
          if (response.data == true) {
            this.registros.items = [];
            this.get_datos();
            window.dialogLoader.showSnackbar('El registro se guardó correctamente..', {
              color: 'success'
            });
          } else {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: response.data,
              footer: ""
            });
          }

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error guardar el registro con bandera compra a proveedor.",
            footer: error
          });
        }).then(() => {
          window.dialogLoader.hide();
        });
    },

    saveRegistro: function() {
      if (this.$refs.formajuste.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_equivalencia/" + this.model.id_articulo;
        let data = {
          "compra_a_proveedor": this.model.compra_a_proveedor,
          "id_articulo_equivalente": this.model.id_articulo_equivalente,
          "codigos_equivalente": this.model.codigo_articulo_equivalente,
          "nombre_articulo_equivalente": this.model.nombre_articulo_equivalente,
          "cantidad": this.model.cantidad,
        }

        window.axios
          .post(url, data)
          .then(response => {
            if (response.data == true) {
              this.modalAjuste = false;
              if (this.model.compra_a_proveedor == 1) {
                this.setProveedorArticulo(this.model.id_articulo, this.model.id_articulo_equivalente);
              } else {
                this.registros.items = [];
                this.get_datos();
                window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                  color: 'success'
                });
              }
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }

          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error crear el registro.",
              footer: error
            });
          }).then(() => {
            if (this.model.compra_a_proveedor != 1)
              window.dialogLoader.hide();
          });
      }
    },
    editarRegistro: function(row) {
      this.model = row;
      this.openModalAjuste(2);
    },
    updateRegistro: function() {
      if (this.$refs.formajuste.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');

        this.model.usuario = this.$local_storage.get("sb_usuario");
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/update_equivalencia/" + this.model.id_articulo;

        window.axios
          .put(url, this.model)
          .then(response => {
            if (response.data == true) {
              this.modalAjuste = false;
              if (this.model.compra_a_proveedor == 1) {
                this.setProveedorArticulo(this.model.id_articulo, this.model.id_articulo_equivalente);
              } else {
                this.registros.items = [];
                this.get_datos();
                window.dialogLoader.showSnackbar('El registro se guardó correctamente..', {
                  color: 'success'
                });
              }
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }


          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modalAjuste = false;
            if (this.model.compra_a_proveedor != 1)
              window.dialogLoader.hide();
          });
      }
    },

    deleteRegistro: function(item) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/delete_equivalencia/" + item.id_articulo;
          window.axios
            .post(url, item)
            .then(response => {
              if (response.data == true) {
                this.registros.items = [];
                this.get_datos();
                window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                  color: 'success'
                });
              } else {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: response.data,
                  footer: ""
                });
              }
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
            }).then(() => {
              window.dialogLoader.hide();
            });

        }

      });
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (isLt2M) {
        return true;
      }
      this.$swal({
        type: "warning",
        title: "¡Operación no Permitida!",
        text: "El archivo no debe ser mayor a 2MB.",
        footer: ""
      });
      return false;
    },
    getArticulosAll: function() {
      var self = this;
      return new Promise(function(resolve, reject) {

        let data = {
          "selector": {
            "type": "articulos"
          },
          "fields": ["_id", "nombre", "codigo_barras"],
        };

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
              self.articulos_all = response.data.docs;
            } else
              self.articulos_all = [];
            return resolve(true);
          })
          .catch(error => {
            return reject(error);
          });
      });
    },
    verificaObligatorios: function(results, camposObligatorios) {
      var errores = '';
      var keys = [];
      for (var k in results) {
        keys.push(k);
      }

      camposObligatorios.forEach(function(campo) {
        if (keys.indexOf(campo) == -1)
          errores += '<br/>' + "- El campo '" + campo + "' es obligatorio.";
      });

      return errores == '' ? true : errores;
    },
    validaArticuloImport: function(val) {
      var filter = this.articulos_all.find(function(e) {
        return e.codigo_barras.includes(val.toString().toUpperCase().trim());
      });
      if (filter) {
        return filter;
      } else
        return "";
    },
    validaCargaLayout: async function(results) {
      var self = this;
      return new Promise(async function(resolve, reject) {
        var errores = 0;
        var articulos_encontrados = [];
        var erroresText = '';
        var errorTmp = '';
        const patternFloat = /^[\d]*(\.{0,1}[\d]*)$/;
        const patternEntero = /^[\d]*$/;
        try {
          if (results.length == 0) {
            errores++;
            erroresText += "No hay datos en el layout. Verifique que exista información a partir de la tercera fila.";
          } else if (results.length > 15000) {
            errores++;
            erroresText += "El máximo de filas a procesar es 15000, favor de verificar.";
          } else {
            for (var k in results) {
              var obligatorios = self.verificaObligatorios(results[k], self.camposObligatorios);
              if (obligatorios !== true) {
                errores++;
                if (erroresText.indexOf(obligatorios) == -1)
                  erroresText += obligatorios;
              } else {

                var articulos_validos = {};

                var codigo = results[k]['codigo_barras_articulo_origen'].toString().trim().toUpperCase();
                var codigo_equivalente = results[k]['codigo_barras_articulo_equivalente'].toString().trim().toUpperCase();
                results[k]["id_articulo"] = null;
                results[k]["id_articulo_equivalente"] = null;

                if (articulos_validos[codigo] != null) {
                  results[k]["id_articulo"] = articulos_validos[codigo];
                } else {
                  var articuloBuscado = self.validaArticuloImport(codigo);
                  if (articuloBuscado != "") {
                    articulos_validos[codigo] = articuloBuscado;
                    results[k]["id_articulo"] = articuloBuscado;
                  } else {
                    errores++;
                    errorTmp = '<br/>' + "- El código " + codigo + " no fue encontrado.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  }
                }

                if (articulos_validos[codigo_equivalente] != null) {
                  results[k]["id_articulo_equivalente"] = articulos_validos[codigo_equivalente];
                } else {
                  var articuloBuscado = self.validaArticuloImport(codigo_equivalente);
                  if (articuloBuscado != "") {
                    articulos_validos[codigo_equivalente] = articuloBuscado;
                    results[k]["id_articulo_equivalente"] = articuloBuscado;
                  } else {
                    errores++;
                    errorTmp = '<br/>' + "- El código equivalente " + codigo_equivalente + " no fue encontrado.";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  }
                }

                if (results[k]["compra_a_proveedor"] != null) {
                  if (!patternEntero.test(results[k]['compra_a_proveedor']) || (![0, 1].includes(results[k]['compra_a_proveedor']))) {
                    errores++;
                    errorTmp = '<br/>' + "- compra_a_proveedor " + results[k]['compra_a_proveedor'] + " no es válido (0|1).";
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else {
                    results[k]['compra_a_proveedor'] = parseInt(results[k]['compra_a_proveedor']);
                  }
                } else {
                  results[k]["compra_a_proveedor"] = 0;
                }

                if (!patternFloat.test(results[k]['cantidad_equivalente'])) {
                  errores++;
                  errorTmp = '<br/>' + "- Cantidad " + results[k]['cantidad_equivalente'] + " no es cantidad válida.";
                  if (erroresText.indexOf(errorTmp) == -1)
                    erroresText += errorTmp;
                }



                if (results[k]["id_articulo"] != null && results[k]["id_articulo_equivalente"] != null) {
                  if (results[k]["id_articulo"]["_id"] == results[k]["id_articulo_equivalente"]["_id"]) {
                    errores++;
                    errorTmp = '<br/>' + "- El articulo " + codigo + " es el mismo que su equivalente " + codigo_equivalente;
                    if (erroresText.indexOf(errorTmp) == -1)
                      erroresText += errorTmp;
                  } else {
                    var data = {
                      "id_articulo": results[k]["id_articulo"]["_id"],
                      "compra_a_proveedor": parseInt(results[k]["compra_a_proveedor"]),
                      "id_articulo_equivalente": results[k]["id_articulo_equivalente"]["_id"],
                      "codigos_equivalente": results[k]["id_articulo_equivalente"]["codigo_barras"],
                      "nombre_articulo_equivalente": results[k]["id_articulo_equivalente"]["nombre"],
                      "cantidad": parseFloat(results[k]['cantidad_equivalente']),
                    };

                    articulos_encontrados.push(data);
                  }

                }

              }

            }

          }

          return resolve({
            "errores": errores,
            "erroresText": erroresText,
            "articulos": articulos_encontrados,
          });

        } catch (error) {
          console.log(error);
          return reject(error);
        }
      }); //promise
    },
    handleSuccess: async function({
      results,
      header
    }) {
      var errores = 0;
      var erroresText = '';
      var importData = {};
      try {
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        self.getArticulosAll().then(result => {
          self.validaCargaLayout(results).then(async validaciones => {
            window.dialogLoader.show('Espere un momento por favor..');

            if (validaciones.errores > 0) {
              this.$swal({
                type: "warning",
                title: "¡Operación no Permitida!",
                text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                footer: validaciones.erroresText,
              });
              window.dialogLoader.hide();
            } else {
              window.dialogLoader.show('Espere un momento por favor..');

              for (var k in validaciones.articulos) {
                var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_equivalencia/" + validaciones.articulos[k].id_articulo;
                var data = validaciones.articulos[k];

                await window.axios
                  .post(url, data).then(response => {}).catch(error => {
                    errores += 1;
                    console.log(error);
                  });
              }

              if (errores > 0) {
                self.$swal({
                  type: "warning",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al importar la plantilla. Favor de intentar nuevamente.",
                  footer: "",
                });
                window.dialogLoader.hide();
              } else {
                self.$swal({
                  type: "success",
                  title: "¡Operación Exitosa!",
                  text: "Los datos se guardaron correctamente.",
                  footer: "",
                });
                self.modalImportar = false;
                window.dialogLoader.hide();
                this.registros.items = [];
                this.get_datos();
              }

            }
          }).catch(error => {
            self.$swal({
              type: "warning",
              title: "¡Operación no Permitida!",
              text: "Error al cargar el archivo",
              footer: error,
            });
            console.log(error);
            window.dialogLoader.hide();

          });
        }).catch(error => {
          window.dialogLoader.hide();
          self.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los artículos.",
            footer: ""
          });
        });


      } catch (error) {
        this.$swal({
          type: "warning",
          title: "¡Operación no Permitida!",
          text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
          footer: error.message,
        });
        window.dialogLoader.hide();
      }
    },


  }
};
</script>
